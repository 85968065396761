import { Form } from "react-bootstrap";

import GeneralFormModule from "./GeneralForm.module.scss";
import { type UseFormRegister } from "react-hook-form";

// 受け取るPropsの型を定義する
interface GeneralFormProps {
  register: UseFormRegister<any>;
  label: string;
  formName: string;
}

export const GeneralForm: React.FC<GeneralFormProps> = ({
  register,
  label,
  formName,
}: GeneralFormProps) => {
  return (
    /** 汎用INPUTボックス **/
    <div
      className={`${GeneralFormModule["general-div"]} d-flex justify-content-between py-2`}
    >
      <div className="my-auto">{label}</div>
      <div>
        {"￥ "}
        <Form.Control
          className={`${GeneralFormModule["general-input"]} my-auto`}
          type="number"
          placeholder="0"
          {...register(formName)}
        />
      </div>
    </div>
  );
};
