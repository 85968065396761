import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { type Item } from "src/models/item";

interface TopState {
  // ASIN or JAN
  code: string;
  // 仕入れ価格
  costPrice: number | undefined;
  // タブキー
  tabKey: string;
  // 商品情報
  product: Item | undefined;
}

// コンポーネント全体で状態管理する項目
const state: TopState = {
  code: "",
  costPrice: undefined,
  tabKey: "fba",
  product: undefined,
};

export const topSlice = createSlice({
  name: "topSlice",
  initialState: state,
  reducers: {
    // Topページをリセット
    reset: (state) => {
      state.code = "";
      state.costPrice = undefined;
    },
    // コードと仕入れ価格をセット
    setCodeAndPrice: (
      state,
      action: PayloadAction<{ code: string; costPrice: number | undefined }>
    ) => {
      state.code = action.payload?.code;
      state.costPrice = action.payload?.costPrice;
    },
    // タブキーをセット
    setTabKey: (state, action: PayloadAction<string>) => {
      state.tabKey = action.payload;
    },
    // 商品情報をセット
    setProduct: (state, action: PayloadAction<Item | undefined>) => {
      state.product = action.payload;
    },
  },
});

export const { reset, setCodeAndPrice, setTabKey, setProduct } =
  topSlice.actions;
