import { toCustomString } from "src/common/util";
import FbaFeeModule from "./FbaFee.module.scss";

// 受け取るPropsの型を定義する
interface FbaFeeProps {
  fbaFee: number | undefined;
}

export const FbaFee: React.FC<FbaFeeProps> = ({ fbaFee }: FbaFeeProps) => {
  return (
    /** 出荷費用（FBA手数料） **/
    <div
      className={`${FbaFeeModule["fba-div"]} d-flex justify-content-between py-2`}
    >
      <div className="my-auto">出荷費用（FBA手数料）</div>
      <div className="px-2 my-auto">
        ￥ {toCustomString(fbaFee)}
        <small className={`${FbaFeeModule.small} text-muted`}> (税込)</small>
      </div>
    </div>
  );
};
