import { Accordion, Form } from "react-bootstrap";

import { CustomToggle } from "../CustomToggle/CustomToggle";
import OtherFeeModule from "./OtherFee.module.scss";

import { toCustomString } from "src/common/util";
import { type UseFormRegister } from "react-hook-form";

// 受け取るPropsの型を定義する
interface OtherFeeProps {
  register: UseFormRegister<any>;
  callParentFunc: () => number;
}

export const OtherFee: React.FC<OtherFeeProps> = ({
  register,
  callParentFunc,
}: OtherFeeProps) => {
  return (
    /** その他の費用 **/
    <div className={`${OtherFeeModule["other-fee-div"]} py-2`}>
      <Accordion className="my-auto">
        <div
          className={`${OtherFeeModule["other-fee-div2"]} d-flex justify-content-between`}
        >
          <div className="my-auto">
            <span>その他の費用</span>
            <CustomToggle eventKey="other-fee">詳細</CustomToggle>
          </div>
          <div className="px-2 my-auto">
            ￥ {toCustomString(callParentFunc())}
          </div>
        </div>
        <div>
          <Accordion.Collapse eventKey="other-fee">
            <div
              className={`${OtherFeeModule["acc-collpse"]} font-14 font-res-12 px-2 px-sm-3 py-1 py-sm-2 ms-2 ms-sm-4 my-1 my-sm-2`}
            >
              <div className="d-flex justify-content-between py-1">
                <span className="my-auto">雑費</span>
                <div>
                  {"￥ "}
                  <Form.Control
                    className={`${OtherFeeModule["fee-input"]} font-14 font-res-12 my-auto`}
                    type="number"
                    placeholder="0"
                    {...register("otherCost")}
                  />
                </div>
              </div>
            </div>
          </Accordion.Collapse>
        </div>
      </Accordion>
    </div>
  );
};
