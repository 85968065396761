import PublicityModule from "./Publicity.module.scss";
import bannerPC1 from "src/images/banner_ec_pc.png";
import bannerPC2 from "src/images/banner_mt_pc.png";
import bannerPC3 from "src/images/banner_qs_pc.png";
import bannerPC4 from "src/images/banner_maker_pc.png";
import bannerSP1 from "src/images/banner_ec_sm.png";
import bannerSP2 from "src/images/banner_mt_sm.png";
import bannerSP3 from "src/images/banner_qs_sm.png";
import bannerSP4 from "src/images/banner_maker_sm.png";

// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

/**
 * 広告コンポーネント
 */
export const Publicity: React.FC = () => {
  // const settings = {
  //   arrows: false,
  //   dots: false,
  //   autoplay: true,
  // };

  const banner = [
    {
      pcImage: bannerPC1,
      spImage: bannerSP1,
      url: "https://ec-seller-labo.co.jp/",
    },
    {
      pcImage: bannerPC2,
      spImage: bannerSP2,
      url: "https://www.mono-tracer.com/",
    },
    {
      pcImage: bannerPC3,
      spImage: bannerSP3,
      url: "https://chrome.google.com/webstore/detail/%E3%82%AF%E3%82%A4%E3%83%83%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%83%E3%83%97/goneipfhoijjigipgahimogacmkdbnjo",
    },
    {
      pcImage: bannerPC4,
      spImage: bannerSP4,
      url: "https://liff.line.me/1657858525-XYyqVg6r/landing?follow=%40919jzskt&lp=FKFb4j&liff_id=1657858525-XYyqVg6r",
    },
  ];

  // インデックスの算出
  const idx = Math.floor(new Date().getTime() / 86400000) % banner.length;

  return (
    <div className="d-flex justify-content-center">
      <div className={`${PublicityModule.wrapper} px-3 pb-5 mb-sm-4`}>
        <div className={`${PublicityModule.pill} px-2 mb-1`}>PR</div>
        <img
          role="button"
          className={`${PublicityModule["pr-img"]} d-none d-sm-block`}
          src={banner[idx].pcImage}
          onClick={() => {
            window.open(banner[idx].url);
          }}
        />
        <img
          role="button"
          className={`${PublicityModule["pr-img"]} d-block d-sm-none pb-4`}
          src={banner[idx].spImage}
          onClick={() => {
            window.open(banner[idx].url);
          }}
        />
        {/* <Slider {...settings}>
          <div>
            <img
              role="button"
              className={`${PublicityModule["pr-img"]} d-none d-sm-block`}
              src={bannerPC1}
              onClick={() => {
                window.open("https://ec-seller-labo.co.jp/");
              }}
            />
            <img
              role="button"
              className={`${PublicityModule["pr-img"]} d-block d-sm-none pb-4`}
              src={bannerSP1}
              onClick={() => {
                window.open("https://ec-seller-labo.co.jp/");
              }}
            />
          </div>
          <div>
            <img
              role="button"
              className={`${PublicityModule["pr-img"]} d-none d-sm-block`}
              src={bannerPC2}
              onClick={() => {
                window.open("https://www.mono-tracer.com/");
              }}
            />
            <img
              role="button"
              className={`${PublicityModule["pr-img"]} d-block d-sm-none pb-4`}
              src={bannerSP2}
              onClick={() => {
                window.open("https://www.mono-tracer.com/");
              }}
            />
          </div>
          <div>
            <img
              role="button"
              className={`${PublicityModule["pr-img"]} d-none d-sm-block`}
              src={bannerPC3}
              onClick={() => {
                window.open(
                  "https://chrome.google.com/webstore/detail/%E3%82%AF%E3%82%A4%E3%83%83%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%83%E3%83%97/goneipfhoijjigipgahimogacmkdbnjo"
                );
              }}
            />
            <img
              role="button"
              className={`${PublicityModule["pr-img"]} d-block d-sm-none pb-4`}
              src={bannerSP3}
              onClick={() => {
                window.open(
                  "https://chrome.google.com/webstore/detail/%E3%82%AF%E3%82%A4%E3%83%83%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%83%E3%83%97/goneipfhoijjigipgahimogacmkdbnjo"
                );
              }}
            />
          </div>
          <div>
            <img
              role="button"
              className={`${PublicityModule["pr-img"]} d-none d-sm-block`}
              src={bannerPC4}
              onClick={() => {
                window.open(
                  "https://chrome.google.com/webstore/detail/%E3%82%AF%E3%82%A4%E3%83%83%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%83%E3%83%97/goneipfhoijjigipgahimogacmkdbnjo"
                );
              }}
            />
            <img
              role="button"
              className={`${PublicityModule["pr-img"]} d-block d-sm-none pb-4`}
              src={bannerSP4}
              onClick={() => {
                window.open(
                  "https://chrome.google.com/webstore/detail/%E3%82%AF%E3%82%A4%E3%83%83%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%83%E3%83%97/goneipfhoijjigipgahimogacmkdbnjo"
                );
              }}
            />
          </div>
        </Slider> */}
      </div>
    </div>
  );
};
