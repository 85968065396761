import { configureStore } from "@reduxjs/toolkit";
import { topSlice } from "src/pages/Top/topSlice";

export const store = configureStore({
  reducer: topSlice.reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // シリアライズエラーが出るので暫定対応
        ignoredActions: ["topSlice/setProduct"],
        ignoredPaths: ["product"],
      },
    }),
});
