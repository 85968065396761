import { Amplify } from "aws-amplify";
import awsExports from "../aws-exports";

import { Provider } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { Header } from "../components/common/header/Header";
import { Footer } from "../components/common/footer/Footer";
import { TopContainer } from "src/pages/Top/TopContainer";
import { Agreetment } from "../pages/Agreetmenet/Agreetment";
import { Policy } from "../pages/Policy/Policy";
import { store } from "./store";

import "src/styles/style.scss";

// 一旦認証なし
Amplify.configure({ ...awsExports, aws_appsync_authenticationType: "AWS_IAM" });

const App = (): JSX.Element => {
  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          {/* ヘッダー */}
          <Header />
          {/* メインコンテンツ */}
          <Routes>
            <Route path="/" element={<TopContainer />} />
            <Route path="/agreetment" element={<Agreetment />} />
            <Route path="/policy" element={<Policy />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
          {/* フッター */}
          <Footer />
        </BrowserRouter>
      </Provider>
    </>
  );
};

export default App;
