import { useState } from "react";
import { useAccordionButton } from "react-bootstrap";

import CustomToggleModule from "./CustomToggle.module.scss";
import caretBlueDown from "src/images/caret-blue-down.svg";
import caretBlueUp from "src/images/caret-blue-up.svg";

export const CustomToggle = ({ children, eventKey }: any): any => {
  const [open, setOpen] = useState<boolean>(false);

  const detailOnClick = useAccordionButton(eventKey, () => {
    setOpen(!open);
  });

  return (
    <span
      role="button"
      className={`${CustomToggleModule["acc-toggle"]} font-14 font-res-12 ps-2`}
      onClick={detailOnClick}
    >
      {children}{" "}
      <img
        className={CustomToggleModule["svg-img"]}
        src={open ? caretBlueUp : caretBlueDown}
      />
    </span>
  );
};
