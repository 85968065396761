import { type UseFormRegister } from "react-hook-form";
import { Form } from "react-bootstrap";

import { type FbaInputs } from "./FbaContainer";

import { Loading } from "src/components/common/Loading/Loading";
import { OtherFee } from "../forms/OtherFee/OtherFee";
import { GeneralForm } from "../forms/GeneralForm/GeneralForm";
import { SellPrice } from "../forms/SellPrice/SellPrice";
import { AmznFee } from "../forms/AmznFee/AmznFee";
import { ProfitDisp } from "../forms/ProfitDisp/ProfitDisp";
import { FbaFee } from "../forms/FbaFee/FbaFee";
import { StorageFee } from "../forms/StorageFee/StorageFee";

import FbaModule from "./Fba.module.scss";

interface FbaPresenterProps {
  register: UseFormRegister<FbaInputs>;
  fbaFee: number | undefined;
  sellPriceOnChange: (event: any) => void;
  totalFeeIncTax: number | undefined;
  referralFee: number | undefined;
  closingFee: number | undefined;
  referralFeeDetails: any;
  getTotalStorageFee: () => number | undefined;
  getEstimatedFeePerItem: () => number | undefined;
  getStorageFee: () => number | undefined;
  monthOnClick: (type: number) => any;
  monthType: number;
  getTotalOther: () => number;
  getBreakEvenPrice: () => number | undefined;
  getProfit: () => number | undefined;
  getProfitRate: () => number | undefined;
  isLoading: boolean;
}

/**
 * FBA Viewコンポーネント
 */
export const FbaPresenter: React.FC<FbaPresenterProps> = (
  props: FbaPresenterProps
) => {
  const { register } = props;
  return (
    <div
      className={`${FbaModule.main} font-16 font-res-14 px-2 px-sm-5 py-3 py-sm-4`}
    >
      <Form>
        <div className="px-1 px-sm-4 py-1 py-sm-2">
          {/* 商品価格 */}
          <SellPrice
            register={register}
            callParentFunc={props.sellPriceOnChange}
          />
          {/* Amazon手数料 */}
          <AmznFee
            totalFeeIncTax={props.totalFeeIncTax}
            referralFee={props.referralFee}
            closingFee={props.closingFee}
            referralFeeDetails={props.referralFeeDetails}
          />
          {/* 出荷費用（FBA手数料） */}
          <FbaFee fbaFee={props.fbaFee} />
          {/* 仕入原価 */}
          <GeneralForm
            register={register}
            label="仕入原価"
            formName="costPrice"
          />
          {/* Amazonへの納品 */}
          <GeneralForm
            register={register}
            label="Amazonへの納品"
            formName="deliveryCost"
          />
          {/* 在庫保管手数料 */}
          <StorageFee
            register={register}
            callParentFunc1={props.getTotalStorageFee}
            callParentFunc2={props.getEstimatedFeePerItem}
            callParentFunc3={props.getStorageFee}
            callParentFunc4={props.monthOnClick}
            monthType={props.monthType}
          />
          {/* その他の費用 */}
          <OtherFee register={register} callParentFunc={props.getTotalOther} />
        </div>
        {/* 利益表示 */}
        <ProfitDisp
          register={register}
          callParentFunc1={props.getBreakEvenPrice}
          callParentFunc2={props.getProfit}
          callParentFunc3={props.getProfitRate}
        />
      </Form>
      <Loading isShow={props.isLoading}></Loading>
    </div>
  );
};
