import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { useSelector } from "react-redux";
import { type RootState } from "src/common/rootState.type";

import {
  AmazonFee,
  type ReferralAndClosingFee,
} from "@cielo-blu-team/amazon-fee-lib";
import { CATEGORY_MAP } from "src/common/const";

import { FbaPresenter } from "./FbaPresenter";

export interface FbaInputs {
  monthType: number;
  sellPrice: number | undefined;
  deliveryCost: number | undefined;
  stockCnt: number;
  estimatedCnt: number;
  costPrice: number | undefined;
  otherCost: number | undefined;
  sellCnt: number;
}

/**
 * FBA Logicコンポーネント
 */
export const FbaContainer: React.FC = () => {
  // 状態管理
  const { product } = useSelector((state: RootState) => state);

  // LoadingのState管理
  const [isLoading] = useState(false);
  // FBA手数料のState管理
  const [fbaFee, setFbaFee] = useState<number | undefined>(undefined);
  // 販売手数料とカテゴリ成約手数料のState管理
  const [refCloFee, setRefCloFee] = useState<ReferralAndClosingFee | undefined>(
    undefined
  );
  // Amazon手数料(販売手数料 + 基本成約料 + カテゴリ成約料)（税込）
  const totalFeeIncTax = refCloFee?.totalFeeIncTax;
  // 販売手数料（税抜）
  const referralFee = refCloFee?.referralFee;
  // カテゴリ成約料（税抜）
  const closingFee = refCloFee?.closingFee;

  /**
   * 販売手数料詳細の取得
   */
  const getReferralFeeDetails = (
    racf: ReferralAndClosingFee | undefined
  ): any => {
    if (racf) {
      /** 販売手数料詳細を決定 */
      const referralFeeDetails: any = racf?.referralFeeDetails;
      referralFeeDetails.categoryName =
        CATEGORY_MAP[product?.feeCategory ?? ""] ?? "不明";
      return referralFeeDetails;
    } else {
      return undefined;
    }
  };

  // 販売手数料詳細
  const referralFeeDetails = getReferralFeeDetails(refCloFee);

  const { register, setValue, watch } = useForm<FbaInputs>({
    defaultValues: {
      monthType: 1,
      sellPrice: 0,
      deliveryCost: undefined,
      stockCnt: 1,
      estimatedCnt: 1,
      costPrice: undefined,
      otherCost: undefined,
      sellCnt: 1,
    },
  });

  const {
    costPrice,
    deliveryCost,
    estimatedCnt,
    otherCost,
    monthType,
    sellCnt,
    sellPrice,
    stockCnt,
  } = watch();

  // productの変更検知
  useEffect(() => {
    setValue("sellPrice", product?.actPrice);
    setValue("costPrice", product?.costPrice);

    setFbaFee(getFbaFee(Number(product?.actPrice)));
    setRefCloFee(getReferralAndClosingFeeInfo(Number(product?.actPrice)));
  }, [product]);

  /**
   * 在庫保管手数料の月タイプ変更時
   * @param type タイプ
   */
  const monthOnClick = (type: number): any => {
    setValue("monthType", type);
  };

  /**
   * 商品価格の変動時に発火
   * @param event
   */
  const sellPriceOnChange = (event: any): void => {
    const sellPrice = Number(event.target.value);
    setValue("sellPrice", sellPrice);
    setFbaFee(getFbaFee(sellPrice));
    setRefCloFee(getReferralAndClosingFeeInfo(sellPrice));
  };

  /**
   * ライブラリを利用してFBA手数料の取得
   */
  const getFbaFee = (sellPrice: number): number | undefined => {
    return AmazonFee.getFbaFee(
      sellPrice,
      Number(product?.packageLength),
      Number(product?.packageWidth),
      Number(product?.packageHeight),
      Number(product?.packageWeight)
    )?.fbaFee;
  };

  /**
   * ライブラリを利用して販売手数料とカテゴリ成約手数料の取得
   */
  const getReferralAndClosingFeeInfo = (
    sellPrice: number
  ): ReferralAndClosingFee => {
    /** 販売手数料とカテゴリ成約手数料を取得 */
    return AmazonFee.getReferralAndClosingFee(
      sellPrice,
      product?.feeCategory ?? "",
      product?.leafNodeId
    );
  };

  /**
   * 合計在庫保管手数料の計算
   * @returns
   */
  const getTotalStorageFee = (): number | undefined => {
    if (getStorageFee() === undefined) {
      return undefined;
    } else {
      return (getStorageFee() as number) * stockCnt;
    }
  };

  /**
   * 在庫保管手数料の計算
   * @returns
   */
  const getStorageFee = (): number | undefined => {
    return monthType === 1
      ? product?.normalStorageFee
      : product?.specialStorageFee;
  };

  /**
   * 1商品あたりの在庫保管手数料の計算
   * @returns
   */
  const getEstimatedFeePerItem = (): number | undefined => {
    if (getTotalStorageFee() === undefined) {
      return undefined;
    } else {
      return (getTotalStorageFee() as number) / Number(estimatedCnt);
    }
  };

  /**
   * その他の費用の合計の計算
   * @returns
   */
  const getTotalOther = (): number => {
    return Number(otherCost);
  };

  /**
   * 損益分岐価格の計算
   * @returns
   */
  const getBreakEvenPrice = (): number | undefined => {
    if (
      totalFeeIncTax === undefined ||
      getEstimatedFeePerItem() === undefined ||
      fbaFee === undefined
    ) {
      return undefined;
    } else {
      return (
        Number(deliveryCost) +
        Number(totalFeeIncTax) +
        Number(getEstimatedFeePerItem()) +
        Number(fbaFee) +
        getTotalOther() +
        Number(costPrice ?? 0)
      );
    }
  };

  /**
   * 純利益額の計算
   * @returns
   */
  const getProfit = (): number | undefined => {
    if (getBreakEvenPrice() === undefined) {
      return undefined;
    } else {
      return (
        (Number(sellPrice) - Number(getBreakEvenPrice())) * Number(sellCnt)
      );
    }
  };

  /**
   * 純利益率の計算
   * @returns
   */
  const getProfitRate = (): number | undefined => {
    return (
      ((Number(sellPrice) - Number(getBreakEvenPrice())) / Number(sellPrice)) *
      100
    );
  };

  const args = {
    register,
    fbaFee,
    sellPriceOnChange,
    totalFeeIncTax,
    referralFee,
    closingFee,
    referralFeeDetails,
    getTotalStorageFee,
    getEstimatedFeePerItem,
    getStorageFee,
    monthOnClick,
    monthType,
    getTotalOther,
    getBreakEvenPrice,
    getProfit,
    getProfitRate,
    isLoading,
  };

  return <FbaPresenter {...args} />;
};
