import { Form } from "react-bootstrap";

import SellPriceModule from "./SellPrice.module.scss";
import { type UseFormRegister } from "react-hook-form";

// 受け取るPropsの型を定義する
interface SellPriceProps {
  register: UseFormRegister<any>;
  callParentFunc: (event: any) => void;
}

export const SellPrice: React.FC<SellPriceProps> = ({
  register,
  callParentFunc,
}: SellPriceProps) => {
  return (
    /** 商品価格 **/
    <div
      className={`${SellPriceModule["price-div"]} d-flex justify-content-between py-2`}
    >
      <div className="my-auto">商品価格</div>
      <div>
        {"￥ "}
        <Form.Control
          className={`${SellPriceModule["price-input"]} my-auto`}
          type="number"
          {...register("sellPrice")}
          onChange={callParentFunc}
        />
      </div>
    </div>
  );
};
