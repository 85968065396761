import { type UseFormRegister } from "react-hook-form";
import { Button, Form } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import { ProductContainer } from "./Product/ProductContainer";
import { Publicity } from "src/components/common/Publicity/Publicity";

import { type TopInputs } from "./TopContainer";
import caretGrayUp from "src/images/caret-gray-up.svg";

import TopModule from "./Top.module.scss";

interface TopPresenterProps {
  code: string;
  clickedSearchBtn: any;
  register: UseFormRegister<TopInputs>;
  isDirty: boolean;
  isValid: boolean;
  isVisible: boolean;
  clickedFabBtn: () => void;
}

/**
 * Top Viewコンポーネント
 */
export const TopPresenter: React.FC<TopPresenterProps> = (
  props: TopPresenterProps
): JSX.Element => {
  return (
    <>
      <main className="pt-0 pt-sm-4">
        <div
          className={`${TopModule.wrapper} row g-0 justify-content-center px-2 py-4 mx-auto`}
        >
          <div className="col-12 px-2 pt-5 pt-sm-3 pb-3">
            <Form onSubmit={props.clickedSearchBtn}>
              <Form.Group>
                <Form.Label
                  className={`${TopModule["head-line"]} w-100 text-center fw-bold`}
                >
                  Amazonの販売手数料をチェック
                </Form.Label>
                <div className="d-block px-sm-5">
                  <Form.Control
                    type="search"
                    className={`${TopModule["custom-input"]} ${
                      props.isDirty && !props.isValid && "invalid"
                    }`}
                    maxLength={13}
                    placeholder="JANコードまたはASINを検索"
                    {...props.register("code", {
                      required: true,
                      pattern: {
                        value: /^([A-Z0-9]{10}|[0-9]{13})$/,
                        message: "不正な形式です",
                      },
                    })}
                  />
                  <div className={`${TopModule["search-btn-div"]} text-center`}>
                    <Button
                      className={`${TopModule["search-btn"]}`}
                      variant="primary"
                      type="submit"
                      disabled={!props.isValid}
                    >
                      <FontAwesomeIcon icon={faSearch} />
                      <span className="fw-bold ps-1">検索する</span>
                    </Button>
                  </div>
                </div>
              </Form.Group>
            </Form>
          </div>
        </div>
        {props.code !== "" ? <ProductContainer /> : <Publicity />}
      </main>
      <div
        role="button"
        className={props.isVisible ? TopModule["fab-div"] : "d-none"}
        onClick={props.clickedFabBtn}
      >
        <div className="m-auto">
          <img src={caretGrayUp} />
          <div className="font-16 font-res-12">TOP</div>
        </div>
      </div>
    </>
  );
};
