import { type UseFormRegister } from "react-hook-form";
import { Form } from "react-bootstrap";

import { type FbmInputs } from "./FbmContainer";

import { Loading } from "src/components/common/Loading/Loading";
import { OtherFee } from "../forms/OtherFee/OtherFee";
import { GeneralForm } from "../forms/GeneralForm/GeneralForm";
import { SellPrice } from "../forms/SellPrice/SellPrice";
import { AmznFee } from "../forms/AmznFee/AmznFee";
import { ProfitDisp } from "../forms/ProfitDisp/ProfitDisp";
import { StorageFee } from "../forms/StorageFee/StorageFee";

import FbmModule from "./Fbm.module.scss";

interface FbmPresenterProps {
  register: UseFormRegister<FbmInputs>;
  sellPriceOnChange: (event: any) => void;
  totalFeeIncTax: number | undefined;
  referralFee: number | undefined;
  closingFee: number | undefined;
  referralFeeDetails: any;
  getTotalStorageFee: () => number | undefined;
  getEstimatedFeePerItem: () => number | undefined;
  getTotalOther: () => number;
  getBreakEvenPrice: () => number | undefined;
  getProfit: () => number | undefined;
  getProfitRate: () => number | undefined;
  isLoading: boolean;
}

/**
 * FBM Viewコンポーネント
 */
export const FbmPresenter: React.FC<FbmPresenterProps> = (
  props: FbmPresenterProps
) => {
  const { register } = props;

  return (
    <div
      className={`${FbmModule.main} font-16 font-res-14 px-2 px-sm-5 py-3 py-sm-4`}
    >
      <Form>
        <div className="px-1 px-sm-4 py-1 py-sm-2">
          {/* 商品価格 */}
          <SellPrice
            register={register}
            callParentFunc={props.sellPriceOnChange}
          />
          {/* 配送料 */}
          <GeneralForm
            register={register}
            label="配送料"
            formName="shippingCost"
          />
          {/* Amazon手数料 */}
          <AmznFee
            totalFeeIncTax={props.totalFeeIncTax}
            referralFee={props.referralFee}
            closingFee={props.closingFee}
            referralFeeDetails={props.referralFeeDetails}
          />
          {/* 仕入原価 */}
          <GeneralForm
            register={register}
            label="仕入原価"
            formName="costPrice"
          />
          {/* 出荷費用 */}
          <GeneralForm
            register={register}
            label="出荷費用"
            formName="deliveryCost"
          />
          {/* 在庫保管手数料 */}
          <StorageFee
            register={register}
            callParentFunc1={props.getTotalStorageFee}
            callParentFunc2={props.getEstimatedFeePerItem}
          />
          {/* その他の費用 */}
          <OtherFee register={register} callParentFunc={props.getTotalOther} />
        </div>
        {/* 利益表示 */}
        <ProfitDisp
          register={register}
          callParentFunc1={props.getBreakEvenPrice}
          callParentFunc2={props.getProfit}
          callParentFunc3={props.getProfitRate}
        />
      </Form>
      <Loading isShow={props.isLoading}></Loading>
    </div>
  );
};
