import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { reset } from "src/pages/Top/topSlice";

import HeaderModule from "./Header.module.scss";
import title from "src/images/title.png";

/**
 * Headerコンポーネント
 */
export const Header: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  /**
   * Topページ以外はリセットしてTopページに遷移
   * それ以外は内容をリセットして表示
   */
  const changePath = (): void => {
    dispatch(reset());
    if (location.pathname !== "/") {
      navigate("/");
    }
  };

  return (
    <header className="d-flex">
      <img
        role="button"
        className={`${HeaderModule["title-img"]} px-3 py-1 my-auto`}
        src={title}
        onClick={changePath}
      />
    </header>
  );
};
