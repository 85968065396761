import { useNavigate } from "react-router-dom";
import FooterModule from "./Footer.module.scss";

/**
 * Footerコンポーネント
 */
export const Footer: React.FC = () => {
  const year = new Date().getFullYear();
  const navigate = useNavigate();

  return (
    <footer className="w-100">
      <div className={`${FooterModule["footer-box"]} d-flex`}>
        <div className={`${FooterModule.wrapper} my-auto`}>
          <div className="row justify-content-center g-0 w-100 pb-1">
            <div
              className={`${FooterModule["right-line"]} col-auto d-flex px-3 px-sm-4`}
            >
              <span
                role="button"
                className={`${FooterModule["list-item"]} my-auto`}
                onClick={() => {
                  navigate("agreetment");
                }}
              >
                ご利用規約
              </span>
            </div>
            <div className="col-auto d-flex px-3 px-sm-4">
              <span
                role="button"
                className={`${FooterModule["list-item"]} my-auto`}
                onClick={() => {
                  navigate("policy");
                }}
              >
                個人情報保護方針
              </span>
            </div>
          </div>
          <div className={`${FooterModule.copyright}`}>
            <span className="my-auto">
              Copyright@ {year} FeeFlex All rights reserved
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};
