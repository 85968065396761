import AgreetmentModule from "./Agreetment.module.scss";

export const Agreetment: React.FC = () => {
  return (
    /** 利用規約 **/
    <div className={`${AgreetmentModule.wrapper} px-3 px-sm-5 pt-3`}>
      <h4 className="section-title py-2">
        <div className="mb-0">
          <svg
            width="27"
            height="27"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.8195 19L19 13.8195H13.8195V19ZM3.45488 3.45488V19H12.9549V12.9549H19V3.45488H3.45488ZM15.5451 0H0V15.5451H1.72559V1.72559H15.5451V0Z"
              fill="#3E4958"
            ></path>
          </svg>
          <span className="px-2"> ご利用規約</span>
        </div>
      </h4>
      <div className={`${AgreetmentModule.main} px-4 py-4`}>
        <strong>第１条（総則）</strong>
        <br />
        １、本規約は、当社が運営するサービス「
        FeeFlex」（以下「本サイト」）の利用について定めます。
        <br />
        ２、本規約とは別に当社が定める諸規約は、本規約の一部を構成するものとします。
        <br />
        ３、本規約と前項の別規約が異なる場合は、別に定める規約を適用します。
        <br />
        ４、本サイトを利用する場合、本規約に定める内容を熟読・理解した上で遵守しなければなりません。
        <br />
        ５、本サイトのコンテンツにアクセスした場合、本規約を熟読・理解し、承認のうえ本サイトを利用したものとみなします。
        <br />
        ６、当社は、必要に応じて利用者の承諾を得ずに本規約の全部または一部を変更することがあります。変更後の規約の効力は、本サイトに変更後の規約が掲載されたときに
        生じるものとします。
        <br />
        ７、本規約の一部が無効であると判断された場合であっても、無効と判断された部分を除いて効力を有するものとします。
        <br />
        <br />
        <strong>第２条（用語の定義）</strong>
        <br />
        １、利用者とは、本規約を理解し、承認の上、本サイトを利用する者をいいます。
        <br />
        ２、会員とは、利用者のうち、本サイトの会員登録の申し込みをし、当社が承認した者をいいます。
        <br />
        <br />
        <strong>第３条（利用者の責務）</strong>
        <br />
        １、
        利用者は、本サイトの利用による一切の行為及びその利用の結果に基づく損害について一切の責任を負わなければなりません。
        <br />
        ２、
        利用者は、本サイトを利用することによって当社または第３者に損害を与えた場合はその損害を賠償しなければなりません。
        <br />
        ３、
        利用者は、第１１条に定める禁止事項に該当し当社又は第３者に対し損害を与えた場合その損害を賠償しなければなりません。
        <br />
        <br />
        <strong>第４条（会員登録）</strong>
        <br />
        １、本サイトは一部、会員登録した者のみ利用できるサービスがあります。
        <br />
        ２、会員登録は本規約を理解し、承諾の上、当社に対し会員登録の申し込みを行い、当社が審査の上承認したときに登録が完了します。
        <br />
        ３、会員登録希望者は下記に定める事項に同意したものとします。
        <br />
        &nbsp; &nbsp;
        ・当社の定める書式に従い必要な情報を当社に対して提出し、会員登録の申し込みを行うこと。
        <br />
        &nbsp; &nbsp; ・当社の定める規則に従うこと。
        <br />
        &nbsp; &nbsp;
        ・会員登録申込に際して、提出された情報の他に当社が必要と認めた情報がある場合は当社の求める情報を提出すること。
        <br />
        &nbsp; &nbsp;
        ・登録削除後も一定期間,当社が会員であったものの情報を保有すること。
        <br />
        &nbsp; &nbsp;
        ・登録した情報に変更があった場合は速やかに変更手続きを行うこと。
        <br />
        &nbsp; &nbsp; ・会員としての地位を第３者に譲渡しないこと。
        <br />
        ４、当社が会員登録を承認することが不適当と認める場合には会員登録を承認しないことができます。
        <br />
        <br />
        <strong>第５条（登録の削除等）</strong>
        <br />
        １、
        会員が登録の削除を希望するときは当社が別途定める方法により会員登録を削除することができます。その場合、すでに支払われた料金等については一切返還しないものとします。
        <br />
        ２、当社は、会員が下記に該当した場合に登録を抹消することができます。
        <br />
        &nbsp; &nbsp; ・本規約に違反する行為を行ったとき。
        <br />
        &nbsp; &nbsp; ・登録情報に虚偽があった場合。
        <br />
        &nbsp; &nbsp;
        ・登録情報に変更があったにも関わらず変更の届け出をしない場合。
        <br />
        &nbsp; &nbsp;
        ・法令等に違反する行為を行った場合、又は行う恐れがある場合。
        <br />
        &nbsp; &nbsp; ・会員登録を継続することが適当でないと当社が認めた場合。
        <br />
        &nbsp; &nbsp; ・第１１条に定める禁止事項を行った場合。
        <br />
        ３、会員登録の削除後は、会員であったことに対する権利の主張を行うことはできません。
        <br />
        <br />
        <strong>第６条（利用環境）</strong>
        <br />
        １、
        本サイトの利用について必要となる機器、ソフトウェア、通信手段等必要となるすべてのものは利用者が準備し、その費用についても利用者が負担するものとします。
        <br />
        <br />
        <strong>第７条（パスワード）</strong>
        <br />
        １、
        当社は会員登録を承認された会員に対し、会員専用のパスワード（以下「自己のパスワード」）、その他必要な情報を付与します。
        <br />
        ２、
        会員は、自己の責任において自己のパスワードを保管し、第３者に知られることのないようにしなければならない。
        <br />
        ３、
        会員は、自己のパスワードを第３者に使用させ、貸与し、譲渡し、売却する等、第３者に権利を移転させることはできません。
        <br />
        ４、
        会員は、自己のパスワードを第３者が使用することにより損害が生じた場合、その損害を賠償しなければなりません。
        <br />
        ５、
        会員は、自己のパスワードが第３者に知られた場合、または知られた恐れがある場合速やか当社に連絡し、当社の指示に従わなければなりません。
        <br />
        <br />
        <strong>第８条（サービス）</strong>
        <br />
        １、
        本サイトでは、Amazon取扱商品の価格、トレンドを取り上げることで、業界の活性化を目的とした情報コンテンツを提供します。
        <br />
        <br />
        <strong>第９条（サービスの変更、追加、停止、廃止）</strong>
        <br />
        １、
        当社は、利用者の承諾なしに本サービスの内容を変更し、項目を追加し、サービスを停止、廃止することができます。
        <br />
        ２、
        当社は、本サービスの内容に変更等が生じた場合は利用者に変更内容を通知します。
        <br />
        ３、 当社は、サービスの変更等について生じた一切の責任を負いません。
        <br />
        <br />
        <strong>第１０条（料金）</strong>
        <br />
        １、本サイトでは会員登録し、有料サービスに申込をしなければ第６条に定める機器等、利用者が準備するものを除き、サービスを無料で利用することができます。
        <br />
        ２、当社は、有料サービスに申込した会員から料金を徴収することができ、会員はその料金を支払う義務を負います。
        <br />
        ３、有料サービスの内容及び料金については申込ぺージに記載します。
        <br />
        ４、当社は、理由の如何を問わず、いったん利用者から支払われた料金を返還しません。
        <br />
        ５、利用者は、理由の如何を問わず、いったん支払義務の生じた料金の支払いを免れることはできません。
        <br />
        <br />
        <strong>第１１条（禁止事項）</strong>
        利用者は下記に定める行為をしてはいけません。
        <br />
        １、 不正の目的を持って本サービスを利用する行為。
        <br />
        ２、
        第３者の信用、名誉、プライバシー、肖像権、パブリシティー権、その他の権利を不当に侵害すること、及びその恐れのある行為
        <br />
        ３、 第３者に損害を与え、または損害を与える恐れのある行為
        <br />
        ４、 本サイトの運営を妨げる行為、誹謗中傷するような行為
        <br />
        ５、
        虚偽の事実を申告、記載、、陳述し、または重要な事項を隠匿したり、申告、記載、陳述しないこと。
        <br />
        ６、
        他の会員のパスワード若しくは利用資格を不正に入手、使用すること、または入手しようとすること。
        <br />
        ７、
        コンピュータウイルスを含む電子メールなど有害なコンピュータプログラム等を本サイト及び登録されている会員に送信すること。
        <br />
        ８、 他の利用者の判断に錯誤を与える恐れのある行為
        <br />
        ９、
        購入または発注の意図が全くないにもかかわらずその希望をだし、他の利用者との間で交渉を行うこと。
        <br />
        １０、犯罪行為、もしくは公序良俗に反する行為、あるいは公正な取引慣行に反する行為。
        <br />
        １１、無在庫販売等、Amazonの規約で禁止されている行為を行って販売をし、そのアカウントにて本サービスを利用する行為。
        <br />
        １２、本規約または法令あるいは所属する業界団体の内部規則等に違反する行為。
        <br />
        １３、その他、当社において不適切と判断される行為。
        <br />
        <br />
        <strong>第１２条（免責事項）</strong>
        <br />
        １、
        当社は、利用者または第３者が、本サービスを利用することにより生じた損害について一切の責任を負いません。
        <br />
        ２、
        当社は、本サービスを利用することにより利用者が第３者に与えた損害の責任を一切負いません。
        <br />
        ３、
        当社は、本サービスの内容（真偽、正確性、第三者の利益を侵害していないこと、および法令および業界団体の内部規則等に違反しないこと等々）、提供の状態、アクセス可能性、使用の状況について責任を一切負いません。
        <br />
        ４、
        利用者は、本サイトを利用することにより、第３者と紛争が生じた場合には、利用者自身で解決するものとし、当社は紛争に関して一切関与しません。
        <br />
        ５、
        当社は、利用者間の交渉、取引の成立、個別契約の締結、契約金額の支払等に関しては一切関与しません。
        <br />
        ６、
        当社は、利用者の提供する情報（リンク含む）の正確性については一切関与しません。
        <br />
        ７、
        当社は、サービス内容に付随して他のサービスを紹介する場合がありますが、紹介したサービスについて生じた一切の損害について責任を負いません。
        <br />
        ８、
        サイトの運営上生じたデータの損失、取引上で起こったいかなるトラブルについても責任を負いません。また一切の補填も行いません。
        <br />
        <br />
        <strong>第１３条（個人情報）</strong>
        <br />
        １、
        当社は、利用者の登録した情報および当社が取得した情報（以下「個人情報」）を当社のプライバシーポリシーの基づき適切に取り扱います。
        <br />
        ２、当社はプライバシーポリシーに定める利用目的のために必要な範囲で個人情報の取り扱いを委託先に委託することがあります。
        <br />
        ３、
        当社は、利用者の個人情報を開示する必要がある時は、個人情報を開示する第３者に関する情報、利用目的をあらかじめ利用者に通知し承諾を得るものとします。ただし、下記に該当する場合は、利用者の承諾なしに個人情報を開示することができるものとします。
        <br />
        &nbsp; &nbsp;
        ・刑事訴訟法その他法に定めに基づく強制処分が行われた場合、この処分の定める範囲で開示、提供する場合。
        <br />
        &nbsp; &nbsp;
        ・特定電気通信役務提供者の損害賠償責任の制限及び発信情報の開示に関する法律第４号（発信情報の開示請求等）にもとずく開示請求の要件が満たされた場合にはこの開示請求の範囲で個人情報を開示提供する場合。
        <br />
        &nbsp; &nbsp;
        ・生命、身体または財産の保護のために必要があると当社が判断した場合。
        <br />
        ４、
        当社は、利用者の個人情報が識別されることのない範囲で、利用者の同意なくして統計目的、またはマーケティング目的のために、会員の提供情報、及び会員の本サイトの使用履歴、会員属性を利用し統計結果を一般に公開することがあります。
        <br />
        <br />
        <strong>第１４条（著作権）</strong>
        <br />
        １、
        利用者の提供情報を除いて、本サービスおよび本サイトに含まれるすべてのコンテンツの所有権、著作権、商標権、実用新案権その他の権利はすべて当社に属するものとします。
        <br />
        ２、
        利用者は、本サービスおよび、本サービスを構成する本サイトのコンテンツにつき、事前に当社の書面による同意なく、商業目的その他の目的の如何を問わず、使用、再生、複製、販売等をしてはいけません。
        <br />
        ３、
        ２項に反した場合に、それによって利用者が利益を得た場合は当社はそこで得た利益相当額を当社に対して支払うことに承諾しているものとします。
        <br />
        <br />
        <strong>第１５条（準拠法）</strong>
        <br />
        １、 本規約は、日本国法に準拠し、解釈されるものとします。
        <br />
        <br />
        <strong>第１６条（裁判管轄）</strong>
        <br />
        １、利用者と当社の間で訴訟が生じた場合、当社の所在地を管轄する地方裁判所を第一審の専属的合意管轄裁判所とします。
        <br />
        <br />
        附則 令和2年12月1日制定・施行
        <br />
      </div>
    </div>
  );
};
