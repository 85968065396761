import PolicyModule from "./Policy.module.scss";

export const Policy: React.FC = () => {
  return (
    /** プライバシーポリシー **/
    <div className={`${PolicyModule.wrapper} px-3 px-sm-5 pt-3`}>
      <h4 className="section-title py-2">
        <div className="mb-0">
          <svg
            width="27"
            height="27"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.8195 19L19 13.8195H13.8195V19ZM3.45488 3.45488V19H12.9549V12.9549H19V3.45488H3.45488ZM15.5451 0H0V15.5451H1.72559V1.72559H15.5451V0Z"
              fill="#3E4958"
            ></path>
          </svg>
          <span className="px-2"> 個人情報保護方針</span>
        </div>
      </h4>
      <div className={`${PolicyModule.main} px-4 py-4`}>
        当社は、本サービスをご利用頂いているお客様の個人情報を取り扱う事業者としてその重要性を認識し、個人情報を適切に利用・保護することが社会的責任であると考えております。
        <br />
        また、当社は、個人情報の保護に対して最大限の注意を払い、個人情報の適正な取扱と安全管理に不断の努力を続けることをお約束いたします。
        <br />
        当社は、事業活動をするうえで個人情報保護を重要項目とし、以下の「個人情報保護方針」を制定します。
        <br />
        1.
        当社は、個人情報保護に関する法令、国が定める指針その他の規範を遵守します。
        <br />
        2.
        当社は、個人情報への不正アクセス、個人情報の紛失、破壊、改ざんおよび漏洩などの予防ならびに是正を行います。
        <br />
        3.
        取得した個人情報は、当社のプライバシーポリシーに定めた利用目的の範囲内で取扱い、利用目的の範囲外での利用が行なわれないための確認手順を設け、実施いたします。
        <br />
        4.
        当社は、お客様からの個人情報に関するお問い合わせ、開示等のご請求について、誠実に対応します。
        <br />
        5.
        当社は、個人情報保護に関する管理の体制と仕組みについて継続的改善を実施致します。
        <br />
        令和2年12月1日制定
        <br />
        <br />
        <div className="pb-2">
          <strong>
            <big>プライバシーポリシー</big>
          </strong>
        </div>
        <strong>1．個人情報の定義</strong>
        <br />
        このプライバシーポリシーに述べられている「個人情報」とは、会員ＩＤ、氏名、性別、メールアドレス、電話番号、住所、ニックネーム、ペンネーム、生年月日、職業、役職、クレジットカード情報、購入履歴等、
        当社が保有する情報のうち、一つまたは複数の組み合わせにより、特定の個人を識別することができる情報をいいます。
        <br />
        <br />
        <strong>2．個人情報の利用について</strong>
        <br />
        当社は、個人情報を下記利用目的で利用いたします。
        <br />
        (1) 会員、ユーザーの管理
        <br />
        (2)
        当社及び第三者の商品、権利、デジタルコンテンツ及びサービス（以下、「商品等」といいます。金融商品等を含むものとします。）の販売
        （サービスの提供契約の締結等を含むものとします。以下同じ。）
        <br />
        (3) キャンペーン・懸賞企画、アンケートの実施
        <br />
        (4)
        当社および当社関連会社で会員登録を必要とするサービスを利用するにあたり、会員登録等作業の簡素化
        <br />
        (5)
        本ウェブサイトの運営上必要な事項の通知（電子メールによるものを含むものとします。）
        <br />
        (6)
        当社及び第三者の商品等の広告・宣伝、販売の勧誘（電子メールによるものを含むものとします。）
        <br />
        (7) 弊社関連メールマガジンの送信
        <br />
        (8) 課金計算、料金請求
        <br />
        (9) 各種問合せ、アフターサービス対応
        <br />
        (11) 当社関連会社および業務提携企業に提供する統計資料作成
        <br />
        (12)
        他の事業者等から個人情報の取扱業務を委託された場合、委託された業務の遂行
        <br />
        (13) 契約や法律等に基づく権利の行使や義務の履行
        <br />
        (14) 当社関連会社および業務提携企業に提供する統計資料作成
        <br />
        <br />
        <strong>3．個人情報の管理について</strong>
        <br />
        当社は、個人情報を管理する責任部門に個人情報管理統括責任者を配置し、個人情報の適切な管理を指導しています。
        <br />
        <br />
        <strong>4．個人情報の提供について</strong>
        <br />
        当社は、個人情報を適切に保護し、個人情報保護法その他の法令等により認められた場合を除き、個人識別が可能な状態で同意なく第三者に開示、提供することはありません。
        <br />
        <br />
        <strong>5．セキュリティについて</strong>
        <br />
        個人情報は、当社所定の管理基準に基づき厳重に管理し、個人情報へのアクセス、紛失、破壊、改ざん、漏洩、ウィルス感染等の防止策を講じています。
        <br />
        <br />
        <strong>6．クッキーについて</strong>
        <br />
        弊社で運営するウェブサイトの一部では、クッキー(Cookie)と呼ばれる技術を利用しています。
        クッキーとは、特定の情報をお客様の利用する通信機器に一時的にデータとして保持させ、接続の度にそのデータを基にお客様を識別させる仕組みをいいます。
        当社では、クッキーの利用を前提としたサービスを提供しています。そのため、クッキーの利用を許可しない場合、当社の一部のサービスを受けられないことがあります。
        クッキーの利用を許可するかどうかは、お客様のブラウザで設定できます。必要に応じて設定を確認してください。
        <br />
        <br />
        <strong>7．業務提携企業への情報提供あるいはリンク先について</strong>
        <br />
        当社の業務提携企業、および本ウェブサイトを通じてアクセスできる第三者のサイトおよびサービス等、本ウェブサイトからのリンク先のウェブサイトで独自に収集される個人情報の利用については、当社は、関知いたしません。
        そのため、これらの企業もしくはサイトにおける、独立した規定や活動に対して、当社は一切の義務や責任を負いません。それぞれのサイトのプライバシーポリシーを確認してください。
        <br />
        <br />
        <strong>8．個人情報の開示、訂正、削除について</strong>
        <br />
        当社は、個人情報保護法その他の法令等に基づき、個人情報の開示、訂正、追加、削除、利用停止、消去、第三者提供の停止、利用目的の通知の請求に対応いたします。
        請求が個人情報保護法の定める要件をみたさない場合、ご希望に添えない場合があります。
        <br />
        <br />
        <strong>9．個人情報開示請求等の受付方法について</strong>
        <br />
        個人情報の開示、訂正、追加、削除、利用停止、消去、第三者提供の停止を請求する場合は、当社が別に定めるところに従い、所定の請求書に必要事項をご記入いただき、本人確認書類に当社個人情報顧客相談窓口宛てに郵送して下さい。
        開示請求の場合は、所定の手数料・郵送料も同封してください。
        <br />
        <br />
        <strong>10．個人情報の利用目的の通知請求の受付方法について</strong>
        <br />
        個人情報の利用目的の通知を希望される場合は、info@fee-flex.com
        までお申し出ください。
        <br />
        <br />
        <strong>11．プライバシーポリシーの更新について</strong>
        <br />
        当社は、個人情報保護を図るため、法令等の変更や必要に応じて、本プライバシーポリシーを改訂することがあります。その際は、最新のプライバシーポリシーを本ウェブサイトに掲載いたします。
        <br />
        <br />
        <strong>
          12．プライバシーポリシーに関する意見・苦情・異議申し立てについて
        </strong>
        <br />
        プライバシーポリシーおよび、個人情報の取扱に関するお問い合わせについては、以下の窓口よりご連絡ください。
        <br />
        <br />
        <strong>お問い合せ先</strong>
        <br />
        E-mail：info@fee-flex.com
        <br />
        <br />
        <strong>Amazonアソシエイト・プログラムについて</strong>
        <br />
        当サイトは、Amazon.co.jpを宣伝しリンクすることによってサイトが紹介料を獲得できる手段を提供することを目的に設定されたアフィリエイト宣伝プログラムである、Amazonアソシエイト・プログラムの参加者です。
        <br />
        第三者がコンテンツおよび宣伝を提供し、訪問者から直接情報を収集し、訪問者のブラウザにクッキーを設定したりこれを認識したりする場合があります。
        <br />
        <br />
        <strong>Google Adsenseについて</strong>
        <br />
        当サイトは第三者配信の広告サービス「Google Adsense
        グーグルアドセンス」を利用しています。
        <br />
        広告配信事業者は、ユーザーの興味に応じた広告を表示するためにCookie（クッキー）を使用することがあります。
        <br />
        Cookie（クッキー）を無効にする設定およびGoogleアドセンスに関する詳細は「広告
        – ポリシーと規約 – Google」をご覧ください。
        <br />
        <br />
        <strong>その他</strong>
        <br />
        また、第三者配信の広告サービス （ A8.net、もしも）なども利用しています。
        <br />
        このような広告配信事業者は、 ユーザーの興味に応じた商品や
        サービスの広告を表示するため、
        当サイトや他サイトへのアクセスに関する情報
        『Cookie』(住所、氏名、電話番号、 メールアドレスは含まれません)
        を使用することがあります。
        <br />
      </div>
    </div>
  );
};
