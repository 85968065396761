import { API, graphqlOperation } from "aws-amplify";
import { callFuncGetProduct } from "src/graphql/queries";

import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { type RootState } from "src/common/rootState.type";
import { setProduct, setTabKey } from "../topSlice";

import { ProductPresenter } from "./ProductPresenter";
import { Item } from "src/models/item";

/**
 * 商品情報コンポーネント
 */
export const ProductContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { code, costPrice, tabKey, product } = useSelector(
    (state: RootState) => state
  );
  const [, setSearchParams] = useSearchParams();
  // LoadingのState管理
  const [isLoading, setIsLoading] = useState(false);

  // code変更時のみ実行する
  useEffect(() => {
    void (async () => {
      // ローディング表示
      setIsLoading(true);

      try {
        // 商品基本情報取得
        const { data: res1 }: any = await API.graphql(
          graphqlOperation(callFuncGetProduct, {
            code,
          })
        );

        const parseData1: any = JSON.parse(res1.CallFuncGetProduct);

        // エラーコードがある場合
        if (parseData1?.errorCode) {
          throw parseData1;
        }

        const tmp = new Item({ ...parseData1, costPrice });
        dispatch(setProduct(tmp));

        // クエリパラメータを除去
        setSearchParams({});
      } catch (error: any) {
        console.log(error);
        if (error?.errorCode === 404) {
          alert("該当するデータが見つかりませんでした。");
        } else {
          alert("データ取得に失敗しました。再度実行してください。");
        }
      }

      // ローディング非表示
      setIsLoading(false);
    })();
  }, [code]);

  // タブ選択時に発火
  const selectTab = (key: string): void => {
    dispatch(setTabKey(key));
  };

  const args = {
    product,
    tabKey,
    selectTab,
    isLoading,
  };

  return <ProductPresenter {...args} />;
};
