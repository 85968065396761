import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap";

import { CustomToggle } from "../CustomToggle/CustomToggle";
import AmznFeeModule from "./AmznFee.module.scss";
import info from "src/images/info.svg";

import { toCustomString } from "src/common/util";

// 受け取るPropsの型を定義する
interface AmznFeeProps {
  totalFeeIncTax: number | undefined;
  referralFee: number | undefined;
  closingFee: number | undefined;
  referralFeeDetails: any;
}

export const AmznFee: React.FC<AmznFeeProps> = ({
  totalFeeIncTax,
  referralFee,
  closingFee,
  referralFeeDetails,
}: AmznFeeProps) => {
  return (
    /** Amazon手数料 **/
    <div className={`${AmznFeeModule["amazon-fee-div"]} py-2`}>
      <Accordion className="my-auto">
        <div
          className={`${AmznFeeModule["amazon-fee-div2"]} d-flex justify-content-between`}
        >
          <div className="my-auto">
            <span>Amazon手数料</span>
            <CustomToggle eventKey="amazon-fee">詳細</CustomToggle>
          </div>
          <div className="px-2 my-auto">
            ￥ {toCustomString(totalFeeIncTax)}
            <small className={`${AmznFeeModule.small} text-muted`}>
              {" "}
              (税込)
            </small>
          </div>
        </div>
        <div>
          <Accordion.Collapse eventKey="amazon-fee">
            <div
              className={`${AmznFeeModule["acc-collpse"]} font-14 font-res-12 px-2 px-sm-3 py-1 py-sm-2 ms-2 ms-sm-4 my-1 my-sm-2`}
            >
              <div className="d-flex justify-content-between py-1">
                <span className="d-flex align-items-center">
                  <span>販売手数料</span>
                  <OverlayTrigger
                    placement="right"
                    trigger={["hover", "focus"]}
                    delay={{ show: 100, hide: 300 }}
                    overlay={
                      <Tooltip>
                        <div className={`${AmznFeeModule.tooltip}`}>
                          <div className="d-flex flex-wrap justify-content-between pb-1">
                            <div>手数料ｶﾃｺﾞﾘ名：</div>
                            <div>{referralFeeDetails?.categoryName}</div>
                          </div>
                          <div className="text-start">販売手数料内訳：</div>
                          {referralFeeDetails?.map(
                            (item: any, index: number, ary: any[]) => (
                              <div
                                key={index}
                                className="d-flex justify-content-between ps-1"
                              >
                                <div>
                                  {item.rate * 100}％（￥
                                  {toCustomString(item?.priceSegment)}）
                                </div>
                                <div>￥{toCustomString(item.fee)}</div>
                              </div>
                            )
                          )}
                        </div>
                      </Tooltip>
                    }
                  >
                    <img
                      role="button"
                      className={`${AmznFeeModule["svg-img2"]} ps-1`}
                      src={info}
                    />
                  </OverlayTrigger>
                </span>
                <span>
                  ￥ {toCustomString(referralFee)}{" "}
                  <small className={`${AmznFeeModule.small} text-muted`}>
                    {" "}
                    (税抜)
                  </small>
                </span>
              </div>
              <div className="d-flex justify-content-between py-1">
                <span>基本成約料</span>
                <span>
                  ￥ {toCustomString(0)}
                  <small className={`${AmznFeeModule.small} text-muted`}>
                    {" "}
                    (税抜)
                  </small>
                </span>
              </div>
              <div className="d-flex justify-content-between py-1">
                <span>カテゴリー別成約料</span>
                <span>
                  ￥ {toCustomString(closingFee)}
                  <small className={`${AmznFeeModule.small} text-muted`}>
                    {" "}
                    (税抜)
                  </small>
                </span>
              </div>
            </div>
          </Accordion.Collapse>
        </div>
      </Accordion>
    </div>
  );
};
