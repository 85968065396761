import { Tab, Tabs } from "react-bootstrap";
import { Rating } from "react-simple-star-rating";

import { FbaContainer } from "./Fba/FbaContainer";
import { FbmContainer } from "./Fbm/FbmContainer";
import { Loading } from "src/components/common/Loading/Loading";
import { type Item } from "src/models/item";

import { toCustomString } from "src/common/util";
import ProductModule from "./Product.module.scss";

interface ProductPresenterProps {
  product: Item | undefined;
  tabKey: string;
  selectTab: (key: string) => void;
  isLoading: boolean;
}

/**
 * 商品情報 Viewコンポーネント
 */
export const ProductPresenter: React.FC<ProductPresenterProps> = (
  props: ProductPresenterProps
) => {
  const { product } = props;

  return (
    <div className="d-flex px-3 pb-5">
      <div
        className={`${ProductModule.main} d-flex flex-wrap px-1 px-sm-5 py-3 py-sm-5 mx-auto`}
      >
        <div
          className={`${ProductModule["img-div"]} col-12 col-sm-2 d-flex py-2`}
        >
          <img className="mx-auto" src={product?.imageUrl} />
        </div>
        {/* 基本情報エリア */}
        <div className="col-12 col-sm-10 row g-0 p-2 mx-auto">
          <div className={`${ProductModule["name-div"]} col-12 d-flex py-2`}>
            {product?.itemName}
          </div>
          <div className={`${ProductModule["review-div"]} col-12 d-flex py-2`}>
            評価：
            <div className={`${ProductModule["review-star"]}`}>
              <Rating
                initialValue={product?.reviewRate}
                allowFraction={true}
                size={16}
                readonly
              />
            </div>
            （{product?.reviewCnt}）
          </div>
          <div
            className={`${ProductModule["base-div"]} col-12 d-flex flex-wrap py-2`}
          >
            <div
              className={`${ProductModule["base-left"]} col-12 col-sm-6 row g-0`}
            >
              <div className="col-5 font-res-12 py-1">ASIN</div>
              <div className="col-7 font-res-12 ps-1 py-1">{product?.asin}</div>
              <div className="col-5 font-res-12 py-1">商品重量</div>
              <div className="col-7 font-res-12 ps-1 py-1">
                {product?.packageWeight !== undefined
                  ? toCustomString(product.packageWeight / 1000, 2)
                  : "-"}{" "}
                kg
              </div>
              <div className="col-5 font-res-12 py-1">箱の寸法</div>
              <div className="col-7 font-res-12 ps-1 py-1">
                {product?.packageLength !== undefined
                  ? toCustomString(product.packageLength / 10, 2)
                  : "-"}
                {" x "}
                {product?.packageWidth !== undefined
                  ? toCustomString(product.packageWidth / 10, 2)
                  : "-"}
                {" x "}
                {product?.packageHeight !== undefined
                  ? toCustomString(product.packageHeight / 10, 2)
                  : "-"}{" "}
                cm
              </div>
              <div className="col-5 font-res-12 py-1">売上ランキング</div>
              <div className="col-7 font-res-12 ps-1 py-1">
                {product?.rank !== undefined
                  ? toCustomString(product.rank)
                  : "-"}
                （{product?.rankCategoryName ?? "-"}）
              </div>
              <div className="col-5 font-res-12 py-1">出品者数</div>
              <div className="col-7 font-res-12 ps-1 py-1">
                {product?.newSellerCnt ?? "-"}
              </div>
            </div>
            <div
              className={`${ProductModule["base-right"]} col-12 col-sm-6 row align-self-start align-items-start g-0 p-0 ps-sm-5`}
            >
              <div className="col-5 font-res-12 py-1">商品価格 + 配送料</div>
              <div className="col-7 font-res-12 ps-1 py-1">
                ￥{toCustomString(product?.actPrice)} + ￥
                {toCustomString(product?.actShipping)}
              </div>
              <div className="col-5 font-res-12 py-1">コンディション</div>
              <div className="col-7 font-res-12 ps-1 py-1">新品</div>
              {/* <div className="col-5 font-res-12 py-1">発送元</div>
              <div className="col-7 font-res-12 ps-1 py-1">Amazon</div>
              <div className="col-5 font-res-12 py-1">販売元</div>
              <div className="col-7 font-res-12 ps-1 py-1">Amazon</div> */}
            </div>
          </div>
        </div>

        {/* タブエリア */}
        <div
          className={`${ProductModule["tab-wrapper"]} w-100 mx-1 mt-1 mt-sm-4`}
        >
          <Tabs
            activeKey={props.tabKey}
            onSelect={(key: any) => {
              props.selectTab(key);
            }}
            justify
          >
            <Tab
              tabClassName={`${ProductModule["tab-fba"]} ${
                props.tabKey === "fba" && ProductModule["active-box-shadow"]
              } font-18 font-res-16`}
              eventKey="fba"
              title=""
            >
              <FbaContainer />
            </Tab>
            <Tab
              tabClassName={`${ProductModule["tab-fbm"]} ${
                props.tabKey === "fbm" && ProductModule["active-box-shadow"]
              } font-18 font-res-16`}
              eventKey="fbm"
              title=""
            >
              <FbmContainer />
            </Tab>
          </Tabs>
        </div>
      </div>

      <Loading isShow={props.isLoading}></Loading>
    </div>
  );
};
