import { Form } from "react-bootstrap";

import ProfitDispModule from "./ProfitDisp.module.scss";

import { toCustomString } from "src/common/util";
import { type UseFormRegister } from "react-hook-form";

// 受け取るPropsの型を定義する
interface ProfitDispProps {
  register: UseFormRegister<any>;
  callParentFunc1: () => number | undefined;
  callParentFunc2: () => number | undefined;
  callParentFunc3: () => number | undefined;
}

export const ProfitDisp: React.FC<ProfitDispProps> = ({
  register,
  callParentFunc1,
  callParentFunc2,
  callParentFunc3,
}: ProfitDispProps) => {
  return (
    /** 利益表示 **/
    <div
      className={`${ProfitDispModule["profit-div"]} w-100 row g-0 px-1 px-sm-4 py-2`}
    >
      <div className="col-12 col-sm-3 px-0 px-sm-2 py-1">
        <div
          className={`${ProfitDispModule["profit-row"]} d-flex d-sm-block justify-content-between p-3`}
        >
          <div className="text-center pb-0 pb-sm-2 my-auto">
            1商品あたりの費用
          </div>
          <div className="text-center">
            ￥{" "}
            <span className={`${ProfitDispModule.big} text-center`}>
              {toCustomString(callParentFunc1())}
            </span>
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-3 px-0 px-sm-2 py-1">
        <div
          className={`${ProfitDispModule["profit-row"]} d-flex d-sm-block justify-content-between p-3`}
        >
          <div className="text-center pb-0 pb-sm-2 my-auto">販売個数</div>
          <div className="text-center">
            <Form.Control
              className={`${ProfitDispModule["buy-input"]} my-auto`}
              type="number"
              {...register("sellCnt")}
            />
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-3 px-0 px-sm-2 py-1">
        <div
          className={`${ProfitDispModule["profit-row"]} d-flex d-sm-block justify-content-between p-3`}
        >
          <div className="text-center pb-0 pb-sm-2 my-auto">純利益</div>
          <div className={`${ProfitDispModule.ctext} text-center`}>
            ￥{" "}
            <span className={`${ProfitDispModule.big} text-center`}>
              {toCustomString(callParentFunc2())}
            </span>
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-3 px-0 px-sm-2 py-1">
        <div
          className={`${ProfitDispModule["profit-row"]} d-flex d-sm-block justify-content-between p-3`}
        >
          <div className="text-center pb-0 pb-sm-2 my-auto">純利益率</div>
          <div className={`${ProfitDispModule.ctext} text-center`}>
            <span className={`${ProfitDispModule.big} text-center`}>
              {toCustomString(callParentFunc3(), 2)}
            </span>{" "}
            ％
          </div>
        </div>
      </div>
    </div>
  );
};
