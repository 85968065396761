import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { type RootState } from "src/common/rootState.type";
import { setCodeAndPrice } from "src/pages/Top/topSlice";

import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import { TopPresenter } from "./TopPresenter";

export interface TopInputs {
  code: string;
}

/**
 * Top Logicコンポーネント
 */
export const TopContainer: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  // 状態管理
  const code = useSelector((state: RootState) => state.code);
  // クエリパラメータ
  const [searchParams] = useSearchParams();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isDirty, isValid },
  } = useForm<TopInputs>({
    defaultValues: {
      code: "",
    },
  });

  // code変更時に発火
  useEffect(() => {
    if (!code) {
      // 検索ボックスを空にする
      setValue("code", "");
    }
  }, [code]);

  // 初回のみ実行する
  useEffect(() => {
    const qpCode = searchParams.get("code");
    const qpCostPrice = searchParams.get("cp");

    if (qpCode !== null) {
      setValue("code", qpCode);

      if (!isValid) {
        dispatch(
          setCodeAndPrice({
            code: qpCode,
            costPrice: qpCostPrice ? Number(qpCostPrice) : undefined,
          })
        );
      }
    }

    // FABボタンのイベント登録
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  /**
   * 検索ボタンクリック時
   * @param data フォームデータ
   */
  const clickedSearchBtn = handleSubmit((data: TopInputs) => {
    // コードと仕入れ価格の設定
    dispatch(
      setCodeAndPrice({
        code: data.code,
        costPrice: undefined,
      })
    );
  });

  const [isVisible, setIsVisible] = useState(false);

  /**
   * FABボタンクリック時
   */
  const clickedFabBtn = (): void => {
    window.scroll({ top: 0, behavior: "smooth" });
  };

  /**
   * スクロールでFABボタンの表示制御
   */
  const toggleVisibility = (): void => {
    window.scrollY > 0 ? setIsVisible(true) : setIsVisible(false);
  };

  const args = {
    code,
    clickedSearchBtn,
    register,
    isDirty,
    isValid,
    isVisible,
    clickedFabBtn,
  };

  return <TopPresenter {...args} />;
};
