import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { useSelector } from "react-redux";
import { type RootState } from "src/common/rootState.type";

import {
  AmazonFee,
  type ReferralAndClosingFee,
} from "@cielo-blu-team/amazon-fee-lib";
import { CATEGORY_MAP } from "src/common/const";

import { FbmPresenter } from "./FbmPresenter";

export interface FbmInputs {
  sellPrice: number | undefined;
  shippingCost: number | undefined;
  deliveryCost: number | undefined;
  storageFee: number | undefined;
  stockCnt: number;
  estimatedCnt: number;
  costPrice: number | undefined;
  otherCost: number | undefined;
  sellCnt: number;
}

/**
 * FBM Logicコンポーネント
 */
export const FbmContainer: React.FC = () => {
  // 状態管理
  const { product } = useSelector((state: RootState) => state);

  // LoadingのState管理
  const [isLoading] = useState(false);
  // 販売手数料とカテゴリ成約手数料のState管理
  const [refCloFee, setRefCloFee] = useState<ReferralAndClosingFee | undefined>(
    undefined
  );
  // Amazon手数料(販売手数料 + 基本成約料 + カテゴリ成約料)（税込）
  const totalFeeIncTax = refCloFee?.totalFeeIncTax;
  // 販売手数料（税抜）
  const referralFee = refCloFee?.referralFee;
  // カテゴリ成約料（税抜）
  const closingFee = refCloFee?.closingFee;

  /**
   * 販売手数料詳細の取得
   */
  const getReferralFeeDetails = (
    racf: ReferralAndClosingFee | undefined
  ): any => {
    if (racf) {
      /** 販売手数料詳細を決定 */
      const referralFeeDetails: any = racf?.referralFeeDetails;
      referralFeeDetails.categoryName =
        CATEGORY_MAP[product?.feeCategory ?? ""] ?? "不明";

      return referralFeeDetails;
    } else {
      return undefined;
    }
  };

  // 販売手数料詳細
  const referralFeeDetails = getReferralFeeDetails(refCloFee);

  const { register, setValue, watch } = useForm<FbmInputs>({
    defaultValues: {
      sellPrice: 0,
      shippingCost: undefined,
      deliveryCost: undefined,
      storageFee: undefined,
      stockCnt: 1,
      estimatedCnt: 1,
      costPrice: undefined,
      otherCost: undefined,
      sellCnt: 1,
    },
  });

  const {
    costPrice,
    shippingCost,
    deliveryCost,
    estimatedCnt,
    otherCost,
    sellCnt,
    sellPrice,
    stockCnt,
    storageFee,
  } = watch();

  // productの変更検知
  useEffect(() => {
    setValue("sellPrice", product?.actPrice);
    setValue("costPrice", product?.costPrice);

    setRefCloFee(getReferralAndClosingFeeInfo(Number(product?.actPrice)));
  }, [product]);

  /**
   * 商品価格の変動時に発火
   * @param event
   */
  const sellPriceOnChange = (event: any): void => {
    const sellPrice = Number(event.target.value);
    setValue("sellPrice", sellPrice);
    setRefCloFee(getReferralAndClosingFeeInfo(sellPrice));
  };

  /**
   * 販売手数料とカテゴリ成約手数料の取得
   */
  const getReferralAndClosingFeeInfo = (
    sellPrice: number
  ): ReferralAndClosingFee => {
    /** 販売手数料とカテゴリ成約手数料を取得 */
    return AmazonFee.getReferralAndClosingFee(
      sellPrice,
      product?.feeCategory ?? "",
      product?.leafNodeId
    );
  };

  /**
   * 合計在庫保管手数料の計算
   * @returns
   */
  const getTotalStorageFee = (): number => {
    return Number(storageFee) * Number(stockCnt);
  };

  /**
   * 1商品あたりの在庫保管手数料の計算
   * @returns
   */
  const getEstimatedFeePerItem = (): number => {
    return getTotalStorageFee() / Number(estimatedCnt);
  };

  /**
   * その他の費用の合計の計算
   * @returns
   */
  const getTotalOther = (): number => {
    return Number(otherCost);
  };

  /**
   * 損益分岐価格の計算
   * @returns
   */
  const getBreakEvenPrice = (): number | undefined => {
    if (totalFeeIncTax === undefined) {
      return undefined;
    } else {
      return (
        Number(shippingCost) +
        Number(deliveryCost) +
        Number(totalFeeIncTax) +
        getEstimatedFeePerItem() +
        getTotalOther() +
        Number(costPrice ?? 0)
      );
    }
  };

  /**
   * 純利益額の計算
   * @returns
   */
  const getProfit = (): number | undefined => {
    if (getBreakEvenPrice() === undefined) {
      return undefined;
    } else {
      return (
        (Number(sellPrice) - Number(getBreakEvenPrice())) * Number(sellCnt)
      );
    }
  };

  /**
   * 純利益率の計算
   * @returns
   */
  const getProfitRate = (): number | undefined => {
    return (
      ((Number(sellPrice) - Number(getBreakEvenPrice())) / Number(sellPrice)) *
      100
    );
  };

  const args = {
    register,
    sellPriceOnChange,
    totalFeeIncTax,
    referralFee,
    closingFee,
    referralFeeDetails,
    getTotalStorageFee,
    getEstimatedFeePerItem,
    getTotalOther,
    getBreakEvenPrice,
    getProfit,
    getProfitRate,
    isLoading,
  };

  return <FbmPresenter {...args} />;
};
