import { Modal } from "react-bootstrap";

import LoadingModule from "./Loading.module.scss";

// 受け取るPropsの型を定義する
interface LoadingProps {
  isShow: boolean;
}

export const Loading: React.FC<LoadingProps> = ({ isShow }: LoadingProps) => {
  return (
    <>
      <Modal
        show={isShow}
        contentClassName="loading-modal mx-auto"
        backdrop="static"
        centered
      >
        <Modal.Body>
          <div className={`${LoadingModule["ball-clip-rotate-multiple"]}`}>
            <div></div>
            <div></div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
