/**
 * toLocaleString関数のショートハンド
 * @param data 数値
 * @param decimalDigit 少数桁数
 * @returns
 */
export const toCustomString = (
  data: number | undefined,
  decimalDigit: number = 0
): string => {
  if (data === undefined || data === null) {
    return "";
  }

  return data.toLocaleString("ja-JP", {
    maximumFractionDigits: decimalDigit,
  });
};
