import { CATEGORY_MAP } from "src/common/const";

export class Item {
  /** ASIN */
  asin: string = "";
  /** 商品名 */
  itemName: string | undefined;
  /** 商品画像URL */
  imageUrl: string | undefined;
  /** 商品ランキング */
  rank: number | undefined;
  /** カート価格（ポイントや送料は加味しない） */
  cartPrice: number | undefined;
  /** カート価格の送料 */
  cartShipping: number | undefined;
  /** 最安値新品価格（ポイントや送料は加味しない） */
  newPrice: number | undefined;
  /** 最安値新品価格の送料 */
  newShipping: number | undefined;
  /** 新品出品者数 */
  newSellerCnt: number | undefined;
  /** 商品サイズ */
  size: number = 999;
  /** 梱包サイズ 縦 */
  packageLength: number | undefined;
  /** 梱包サイズ 横 */
  packageWidth: number | undefined;
  /** 梱包サイズ 高さ */
  packageHeight: number | undefined;
  /** 梱包サイズ 重さ */
  packageWeight: number | undefined;
  /** 売上ランクカテゴリ */
  rankCategory: string | undefined;
  /** カテゴリ名 */
  rankCategoryName: string | undefined;
  /** 手数料カテゴリ？ */
  feeCategory: string | undefined;
  /** 末端ノードID */
  leafNodeId: string | undefined;

  /** 販売手数料詳細 */
  referralFeeDetails: any;
  /** カテゴリ別成約料 */
  categoryFee: number | undefined;
  /** カテゴリ別成約料（税込） */
  categoryFeeIncTax: number | undefined;
  /** 在庫保管手数料（通常期） */
  normalStorageFee: number | undefined;
  /** 在庫保管手数料（繁忙期） */
  specialStorageFee: number | undefined;

  /** レビュー数 */
  reviewCnt: number | undefined;
  /** レビュー評価値 */
  reviewRate: number | undefined;

  /** **** 画面設定項目 *******/
  /** カート価格 or 最安値新品価格（ポイントや送料は加味しない） */
  actPrice: number | undefined;
  /** カート価格 or 最安値新品価格の送料 */
  actShipping: number | undefined;
  /** 実質FBA手数料 */
  actFbaFee: number | undefined;
  /** 仕入価格 */
  costPrice: number | undefined;

  /** 軽減税率適用フラグ */
  reducedTaxFlg: boolean = false;

  /** **** 画面設定項目 *******/

  constructor(info: any) {
    // 初期化処理
    this.init();

    // Amazon情報を設定
    this.setProduct(info);
  }

  /**
   * 初期化処理
   * @returns
   */
  public init = (): void => {
    this.asin = "";
    this.itemName = undefined;
    this.imageUrl = undefined;
    this.cartPrice = undefined;
    this.cartShipping = undefined;
    this.newPrice = undefined;
    this.newShipping = undefined;
    this.newSellerCnt = undefined;
    this.rank = undefined;
    this.rankCategory = undefined;
    this.feeCategory = undefined;
    this.rankCategoryName = undefined;
    this.leafNodeId = undefined;
    this.size = 999;
    this.packageLength = undefined;
    this.packageWidth = undefined;
    this.packageHeight = undefined;
    this.packageWeight = undefined;

    this.referralFeeDetails = undefined;
    this.categoryFee = undefined;
    this.categoryFeeIncTax = undefined;
    this.normalStorageFee = undefined;
    this.specialStorageFee = undefined;

    this.reviewCnt = undefined;
    this.reviewRate = undefined;

    /** **** 以下画面設定項目 *******/
    this.actPrice = undefined;
    this.actShipping = undefined;
    this.actFbaFee = undefined;
    this.costPrice = undefined;
    this.reducedTaxFlg = false;

    /** **** 以下画面設定項目 *******/
  };

  /* eslint-disable @typescript-eslint/strict-boolean-expressions */
  /**
   * Amazon情報を設定
   * @param item
   * @returns
   */
  public setProduct = (info: any): void => {
    this.asin = info?.asin || "";
    this.itemName = info?.itemName || "";
    this.imageUrl = info?.itemImgPath || "";
    this.cartPrice = info?.buyboxSeller?.listingPrice;
    this.cartShipping = info?.buyboxSeller?.shipping;
    this.newPrice = info?.newSeller?.listingPrice;
    this.newShipping = info?.newSeller?.shipping;
    this.newSellerCnt = info?.newSellerCnt;
    this.rank = info?.itemRank;
    this.size = info?.itemSize || 999;
    this.packageLength = info?.packingLength;
    this.packageWidth = info?.packingWidth;
    this.packageHeight = info?.packingHeight;
    this.packageWeight = info?.packingWeight;
    this.rankCategory = info?.rankCategory;
    this.feeCategory = info?.categoryId;
    this.leafNodeId = info?.leafNodeId;
    this.categoryFee = info?.categoryFee;
    this.normalStorageFee = info?.normalStorageFee;
    this.specialStorageFee = info?.specialStorageFee;
    this.reviewCnt = info?.reviewCnt;
    this.reviewRate = info?.reviewRate;

    this.costPrice = info?.costPrice;
    this.actPrice = this.cartPrice ?? this.newPrice ?? 0;
    this.actShipping = this.cartShipping ?? this.newShipping ?? 0;

    // 商品画像のトリミング
    if (this.imageUrl !== "") {
      this.imageUrl = `${this.imageUrl?.slice(0, -3)}_SS160_CR0,0,160,160.jpg`;
    }

    /** 売上ランクカテゴリ名 */
    this.rankCategoryName = CATEGORY_MAP[this?.rankCategory ?? ""] ?? "不明";
  };
  /* eslint-enable @typescript-eslint/strict-boolean-expressions */
}
