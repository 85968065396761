import { Accordion, Button, Form } from "react-bootstrap";

import { CustomToggle } from "../CustomToggle/CustomToggle";
import StorageFeeModule from "./StorageFee.module.scss";

import { toCustomString } from "src/common/util";
import { type UseFormRegister } from "react-hook-form";

// 受け取るPropsの型を定義する
interface StorageFeeProps {
  register: UseFormRegister<any>;
  callParentFunc1: () => number | undefined;
  callParentFunc2: () => number | undefined;
  callParentFunc3?: () => number | undefined;
  callParentFunc4?: (type: number) => number | undefined;
  monthType?: number;
}

export const StorageFee: React.FC<StorageFeeProps> = ({
  register,
  callParentFunc1,
  callParentFunc2,
  callParentFunc3,
  callParentFunc4,
  monthType,
}: StorageFeeProps) => {
  return (
    /** 在庫保管手数料 **/
    <div className={`${StorageFeeModule["storage-fee-div"]} py-2`}>
      <Accordion className="my-auto">
        <div
          className={`${StorageFeeModule["storage-fee-div2"]} d-flex justify-content-between`}
        >
          <div className="my-auto">
            <span>在庫保管手数料</span>
            <CustomToggle eventKey="storage-fee">詳細</CustomToggle>
          </div>
          <div className="px-2 my-auto">
            ￥ {toCustomString(callParentFunc1())}
            <small className={`${StorageFeeModule.small} text-muted`}>
              {" "}
              (税込)
            </small>
          </div>
        </div>
        <div>
          <Accordion.Collapse eventKey="storage-fee">
            <div
              className={`${StorageFeeModule["acc-collpse"]} font-14 font-res-12 px-2 px-sm-3 py-1 py-sm-2 ms-2 ms-sm-4 my-1 my-sm-2`}
            >
              {monthType !== undefined ? (
                <>
                  <div className="d-flex py-3">
                    <Button
                      className={`${StorageFeeModule["rounded-btn"]} ${
                        monthType === 1 && StorageFeeModule.active
                      } font-14 font-res-12`}
                      onClick={() => {
                        if (callParentFunc4 !== undefined) {
                          return callParentFunc4(1);
                        }
                      }}
                    >
                      1月～9月
                    </Button>
                    <Button
                      className={`${StorageFeeModule["rounded-btn"]} ${
                        monthType === 2 && StorageFeeModule.active
                      } font-14 font-res-12 ms-2`}
                      onClick={() => {
                        if (callParentFunc4 !== undefined) {
                          return callParentFunc4(2);
                        }
                      }}
                    >
                      10月～12月
                    </Button>
                  </div>
                  <div className="d-flex justify-content-between py-1">
                    <span className="my-auto">1商品あたりの月額保管手数料</span>
                    <span className="px-2 my-auto">
                      ￥{" "}
                      {callParentFunc3 !== undefined ? callParentFunc3() : ""}{" "}
                      <small className={`${StorageFeeModule.small} text-muted`}>
                        {" "}
                        (税込)
                      </small>
                    </span>
                  </div>
                </>
              ) : (
                <div className="d-flex justify-content-between py-1">
                  <span className="my-auto">1商品あたりの月額保管手数料</span>
                  <div className="my-auto">
                    ￥{" "}
                    <Form.Control
                      className={`${StorageFeeModule["fee-input"]} font-14 font-res-12 my-auto`}
                      type="number"
                      placeholder="0"
                      {...register("storageFee")}
                    />
                  </div>
                </div>
              )}
              <div className="d-flex justify-content-between py-1">
                <span className="my-auto">平均在庫保管数</span>
                <div>
                  <Form.Control
                    className={`${StorageFeeModule["fee-input"]} font-14 font-res-12 my-auto`}
                    type="number"
                    {...register("stockCnt")}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between py-1">
                <span className="my-auto">月間販売点数の見積り</span>
                <div>
                  <Form.Control
                    className={`${StorageFeeModule["fee-input"]} font-14 font-res-12 my-auto`}
                    type="number"
                    {...register("estimatedCnt")}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between py-1">
                <span>
                  販売された1商品あたりの
                  <br className="d-inline d-sm-none" />
                  在庫保管手数料
                </span>
                <span className="px-2 my-auto">
                  ￥ {toCustomString(callParentFunc2())}
                  <small className={`${StorageFeeModule.small} text-muted`}>
                    {" "}
                    (税込)
                  </small>
                </span>
              </div>
            </div>
          </Accordion.Collapse>
        </div>
      </Accordion>
    </div>
  );
};
