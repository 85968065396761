/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      dispName
      mail
      role
      spApiFlg
      secret
      settings
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $id: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        dispName
        mail
        role
        spApiFlg
        secret
        settings
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const callFuncGetProduct = /* GraphQL */ `
  query CallFuncGetProduct($code: String!) {
    CallFuncGetProduct(code: $code)
  }
`;
